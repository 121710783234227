// extracted by mini-css-extract-plugin
export var arrowAnimation = "Introsection-module--arrowAnimation--5f596";
export var cardFooter = "Introsection-module--cardFooter--bdb8a";
export var descriptionText = "Introsection-module--descriptionText--e7f43";
export var headingContainer = "Introsection-module--headingContainer--c9d8c";
export var headingIntro = "Introsection-module--headingIntro--88a57";
export var lapImg = "Introsection-module--lapImg--c1290";
export var lapImg2 = "Introsection-module--lapImg2--38502";
export var newBtnn = "Introsection-module--newBtnn--a85b5";
export var paragraphAi = "Introsection-module--paragraphAi--f8b64";
export var sectionContainer = "Introsection-module--sectionContainer--2da12";