import React from "react"
import * as styles from "./Engagement.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData }) {
  return (
    <>
      <div className={styles.engagement}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.paragraph}
            dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
          />
          <Row>
            {strapiData?.cards?.map((card, index) => (
              <Col md={6} key={index}>
                <h3
                  className={styles.headingOther}
                  dangerouslySetInnerHTML={{ __html: card?.title }}
                />
                <p
                  className={styles.paragraphOther}
                  dangerouslySetInnerHTML={{
                    __html: card?.description?.description,
                  }}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Enhancements
