import React from "react"
import * as styles from "./Introsection.module.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

const AIStatisticsTrend = ({ strapiData, predicto, cheer, easyFill }) => {
  return (
    <div>
      <div className={styles.sectionContainer}>
        <Container>
         
          <Row >
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className={styles.headingContainer}
            >
               <h2
            className={styles.headingIntro}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
              <p
                className={styles.descriptionText}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
               <div className={styles.cardFooter}>
                <Link to={"/contact-us/"} className={`${styles.newBtnn} `} >
                  {strapiData?.buttons[0]?.title}                  
                </Link>
              </div>
            </Col>
          </Row>
          <Row >
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}>
          <img
            // src={Screen}
            src={
               "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707479429_678b8303d2.webp"
                
            }
            alt="laptop"
            className={styles.lapImg}
          />
          </Col>
          <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}>
          <img
            // src={Screen}
            src={
               "https://invozone-backend.s3.us-east-1.amazonaws.com/Cover_6583e3043f.webp"
                
            }
            alt="laptop"
            className={styles.lapImg2}
          />
          </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default AIStatisticsTrend
