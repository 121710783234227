// extracted by mini-css-extract-plugin
export var bets = "KeyFeatures-module--bets--02153";
export var card = "KeyFeatures-module--card--59a98";
export var cardHead = "KeyFeatures-module--cardHead--d4448";
export var colPad = "KeyFeatures-module--colPad--9c045";
export var description = "KeyFeatures-module--description--2ea20";
export var easyfillImg = "KeyFeatures-module--easyfillImg--a2c97";
export var heading = "KeyFeatures-module--heading--395b0";
export var laptopContainer = "KeyFeatures-module--laptopContainer--d4a41";
export var mainDes = "KeyFeatures-module--mainDes--5cd4e";
export var ocrBets = "KeyFeatures-module--ocrBets--3b246";
export var ocrCard = "KeyFeatures-module--ocrCard--a35b6";
export var ocrCardHead = "KeyFeatures-module--ocrCardHead--de11d";
export var ocrlaptopContainer = "KeyFeatures-module--ocrlaptopContainer--efebf";
export var ocrmainDec = "KeyFeatures-module--ocrmainDec--8fb6a";