// extracted by mini-css-extract-plugin
export var backgroundImg = "Banner1-module--backgroundImg--416ab";
export var bannerCurve = "Banner1-module--bannerCurve--250af";
export var bannerHeading = "Banner1-module--bannerHeading--b9a45";
export var bannerText = "Banner1-module--bannerText--7f0e5";
export var cheerHead = "Banner1-module--cheerHead--4fb24";
export var dedicated = "Banner1-module--dedicated--25254";
export var dedicatedBanner = "Banner1-module--dedicatedBanner--cd570";
export var dedicatedHeadingStatic = "Banner1-module--dedicatedHeadingStatic--1dbf5";
export var desktopImage = "Banner1-module--desktopImage--4214a";
export var easyFillHead = "Banner1-module--easyFillHead--90760";
export var headingIntro = "Banner1-module--headingIntro--d518c";
export var innerDedicated = "Banner1-module--innerDedicated--65867";
export var mobileImage = "Banner1-module--mobileImage--7b86f";
export var paragraphAi = "Banner1-module--paragraphAi--5ba16";