import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as styles from "./Goals.module.scss"

const WhyInvozone = ({ strapiData }) => {
  // {
  //   console.log(strapiData, "strapiData?.title")
  // }

  return (
    <div className={styles.whySection}>
      <Container>
        <Row>
          <Col lg={4}>
            <h2
              className={styles.mainHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          </Col>
          <Col lg={8}>
            <Row>
              {strapiData?.cards.map((reason, index) => (
                <Col lg={6} key={index}>
                  <div className={styles.reasonCard}>
                    <span className={styles.number}>
                      {String(index + 1).padStart(2, "0")}
                    </span>
                    <div className={styles.titleContainer}>
                      <p className={styles.title}>{reason.title}</p>

                      <p
                        className={styles.titleOther}
                        dangerouslySetInnerHTML={{ __html: reason?.subTitle }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyInvozone
