import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/storageChain/Banner"
import Technologiesweuse from "../../components/storageChain/Technologiesweuse"
import Goals from "../../components/storageChain/Goals"
import Result from "../../components/storageChain/Result"
import Achievements from "../../components/storageChain/Achievements"
import Engagement from "../../components/storageChain/Engagement"
import CenterImage from "../../components/storageChain/CenterImage"
import Predicto from "../../components/storageChain/Predicto"
import AIConsultingService from "../../components/storageChain/Introsection"
import KeyFeatures from "../../components/storageChain/KeyFeatures"
import KeyChallengesSolution from "../../components/invocom/KeyChalanges"
import SEORevamp from "../../components/common/SEO_Revamp"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  console.log("All GraphQL Data:", data)

  console.log("Workflow Data:", data?.allStrapiAiHologram?.nodes[0]?.workflow)
  console.log(
    "Workflow Steps:",
    data?.allStrapiAiHologram?.nodes[0]?.workflow?.workflow_steps?.data
  )
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const predicto = ({ data }) => {
  console.log(data,"dataa")
  const hero = data?.strapiPage?.sections[0]
  const heroSection = data?.strapiPage?.sections[1]
  const consultingService = data?.strapiPage?.sections[2]
  const goals = data?.strapiPage?.sections[5]
  const key = data?.strapiPage?.sections[6]
  const achievements = data?.strapiPage?.sections[7]
  const result = data?.strapiPage?.sections[8]
  const engagementModel = data?.strapiPage?.sections[3]
  const engagement = data?.strapiPage?.sections[9]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={hero}
        breadCrumb={data?.strapiPage?.buttons}
        predicto={true}
        showImage={false}
      />
      <Technologiesweuse hideCurve={true} strapiData={heroSection} />
      <AIConsultingService strapiData={consultingService} predicto={true} />
      <KeyFeatures strapiData={engagementModel} predicto={true} />
      <CenterImage />
      <Goals strapiData={goals} />
      <KeyChallengesSolution strapiData={key} />
      <Predicto />
      <Achievements strapiData={achievements} />
      <Result strapiData={result} />
      <Engagement strapiData={engagement} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query predicto {
    strapiPage(slug: { eq: "storage-chain" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            caseServices {
              invocom_caseStudy {
                skills
              }
              caseStudy
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default predicto
