import React from "react"
import * as styles from "./Result.module.scss"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

function Enhancements({ strapiData, cheer }) {
  return (
    <div className={`${styles.enhancements} ${cheer ? styles.cheerPad : ""}`}>
      <Container>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className={styles.btnContainer}>
              <div className={styles.btnContainerother}>
                <Link
                  to={"/contact-us/"}
                  className={`${styles.bookDemo} newBtnnn`}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4} className="d-flex p-0">
              <div className={styles.cards}>
                <span>0{i + 1}</span>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: e?.title,
                  }}
                ></h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
