import React from "react"
import * as styles from "./Achievements.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData }) {
  // Split cards into two rows
  const firstRowCards = strapiData?.cards?.slice(0, 3)
  const secondRowCards = strapiData?.cards?.slice(3)

  return (
    <div className={styles.enhancements}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
        
        {/* First row - 3 cards */}
        <Row className="gap-30">
          {firstRowCards?.map((e, i) => (
            <Col lg={4} md={12} xs={12} key={i}>
              <div className={styles.cards}>
                <div className={styles.cardContent}>
                  <div className={styles.imageWrapper}>
                    <img src={e?.image1[0]?.localFile?.publicURL} alt="tick" />
                  </div>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: e?.title,
                    }}
                  ></h3>
                  <p
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {/* Second row - 2 centered cards */}
        <Row className="gap-30 justify-content-center">
          {secondRowCards?.map((e, i) => (
            <Col lg={4} md={12} xs={12} key={i}>
              <div className={styles.cards}>
                <div className={styles.cardContent}>
                  <div className={styles.imageWrapper}>
                    <img src={e?.image1[0]?.localFile?.publicURL} alt="tick" />
                  </div>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: e?.title,
                    }}
                  ></h3>
                  <p
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
