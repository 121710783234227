import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./CenterImage.module.scss"

function Conclusion() {
  return (
    <Container>
      <div className={styles.laptopImg}>
        <Row>
          <Col md={4}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2025_01_13_at_1_42_19_PM_1_f4d8e166e1.webp"
              }
              alt="laptop"
              decoding="async"
              loading="lazy"
            />
          </Col>
          <Col md={4}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707479484_6ed092b635.webp"
              }
              alt="laptop"
              decoding="async"
              loading="lazy"
            />
          </Col>
          <Col md={4}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707479483_588643c67c.webp"
              }
              alt="laptop"
              decoding="async"
              loading="lazy"
            />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Conclusion
