import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./KeyFeatures.module.scss"

function PerfectEngagement({ strapiData }) {
  return (
    <>
      <div className={`${styles.bets} `}>
        <Container>
          <h2
            className={`${styles.heading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className="justify-content-center">
            {strapiData?.cards?.map((e, i) => (
              <Col lg={3} className={`${styles.colPad} d-flex gap-30`}>
                <div className={`${styles.card} `}>
                  <img src={e?.image1[0]?.localFile?.publicURL} alt="tick" />
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: e?.title,
                    }}
                    className={`${styles.cardHead} `}
                  ></h3>

                  <p
                    className={`${styles.mainDes} `}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
     
    </>
  )
}

export default PerfectEngagement
